module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://416serg.me"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-segment/gatsby-browser'),
      options: {"plugins":[],"writeKey":"fAteKcWsk9kpjS17AuUZJmkOI7nWg0GN"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#121212"},
    }]
