import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

const Wrapper = styled.section`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 3em 1.5em 2em;
  .highlight {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    position: relative;
    &:before {
      content: '';
      background: ${props => transparentize(0.5, props.theme.colors.highlight)};
      width: 100%;
      position: absolute;
      bottom: 0px;
      height: 5px;
      transform: skew(-12deg);
    }
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const Container = ({ children }) => <Wrapper>{children}</Wrapper>

export default Container
