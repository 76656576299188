import styled from 'styled-components'

export const FormCard = styled.div`
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  padding: 5px;
  border-radius: 5px;
  padding: 2rem;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto;
  background: #fff;
  h3 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  p {
    text-align: center;
    margin-bottom: 1rem;
  }
`

export const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
    border: none;
    outline: none;
    background: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.base};
    border-radius: 2px;
    padding: 1em;
    &::-webkit-input-placeholder {
      color: gray;
    }
    &::-moz-placeholder {
      color: gray;
    }
    &:-ms-input-placeholder {
      color: gray;
    }
    &:-moz-placeholder {
      color: gray;
    }
    &:required {
      box-shadow: none;
    }
    &:focus {
      outline: 2px solid ${props => props.theme.colors.highlight};
    }
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? '.8' : '0')};
    visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
  }
`

export const Name = styled.input`
  margin: 0 0 1em 0;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    margin: 0;
  }
`

export const Email = styled.input`
  margin: 0;

  flex: 1;
`

export const Submit = styled.input`
  background: ${props => props.theme.colors.base} !important;
  cursor: pointer;
  font-size: 1em;
  display: block;
  border: none;
  outline: none;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff !important;
  padding: 5px 15px;
  -webkit-appearance: none;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.2s;
  z-index: 99;
  &:hover,
  &:focus {
    outline: none;
    background: ${props => props.theme.colors.highlight} !important;
  }
`

export const Modal = styled.div`
  background: white;
  padding: 2em;
  border-radius: 2px;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    min-width: inherit;
    max-width: 400px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

export const Button = styled.button`
  background: ${props => props.theme.colors.base};
  font-size: 1em;
  display: inline-block;
  margin: 0 auto;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 4px;
  border-radius: 2px;
  text-decoration: none;
  transition: 0.2s;
  z-index: 99;
  &:hover,
  &:focus {
    outline: none;
    background: ${props => props.theme.colors.highlight};
  }
`
