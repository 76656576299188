import React from 'react'
import styled from 'styled-components'
import pattern from '../images/pattern.svg'

const Banner = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    min-height: 60vh;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${pattern});
`
const Content = styled.div`
  text-align: center;
  padding: 5rem 1rem;

  h2 {
    font-size: 2em;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      font-size: 3em;
    }
    font-weight: 600;
    transform: skew(-12deg);
    background: ${props => props.theme.colors.highlight};
  }
  h3 {
    font-size: 1.5em;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      font-size: 2em;
    }
    font-weight: 600;
  }
`

const HomeBanner = () => (
  <Banner>
    <Content>
      <h2>416serg</h2>
      <h3>Full Stack Javascript Developer</h3>
    </Content>
  </Banner>
)

export default HomeBanner
