import React from 'react'
import PropTypes from 'prop-types'
import { FormCard, Form, Name, Email, Submit } from './styles/FormStyles'

class SubscribeForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
    }
  }

  handleInputChange = (event, field) => {
    const target = event.target
    const value = target.value
    this.setState({
      [field]: value,
    })
  }

  handleSubmit = event => {}

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
    })
  }

  render() {
    const { name, email } = this.state
    const { title, subTitle } = this.props
    return (
      <FormCard>
        {title.length > 0 && <h3>{title}</h3>}
        {subTitle.length > 0 && <p>{subTitle}</p>}
        <Form
          name="subscribe"
          onSubmit={this.handleSubmit}
          action="https://www.getdrip.com/forms/632868853/submissions"
          method="post"
          onClick={this.closeModal}
          data-drip-embedded-form="632868853"
        >
          <div data-drip-attribute="description" />
          <input type="hidden" name="form-name" value="subscribe" />
          <p hidden>
            {/* eslint-disable-next-line */}
          <label>
              Don’t fill this out: <input name="bot" onChange={this.handleInputChange} />
            </label>
          </p>

          <Email
            type="email"
            id="drip-email"
            name="fields[email]"
            placeholder="Email"
            value={email}
            onChange={e => this.handleInputChange(e, 'email')}
            required
          />

          <Submit name="submit" type="submit" data-drip-attribute="sign-up-button" value="Subscribe" />
        </Form>
      </FormCard>
    )
  }
}

SubscribeForm.defaultProps = {
  title: '',
  subTitle: '',
}

SubscribeForm.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
}

export default SubscribeForm
