import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import HomeBanner from '../components/HomeBanner'
import SubscribeForm from '../components/SubscribeForm'
import SEO from '../components/SEO'

const Index = ({ data }) => {
  const posts = data.allContentfulPost.edges

  return (
    <Layout>
      <SEO />
      <HomeBanner />
      <Container>
        <PageTitle small>Recent Blog Posts</PageTitle>
        <CardList>
          {posts.map(({ node: post }) => (
            <Card
              key={post.id}
              slug={post.slug}
              image={post.heroImage}
              title={post.title}
              date={post.publishDate}
              excerpt={post.body}
            />
          ))}
        </CardList>
        <p>
          For more of the things I wrote in the past check out my{' '}
          <a href="https://medium.com/@416serg" className="highlight">
            Medium account
          </a>
          . I will be transferring them over here, just haven't had time to do so yet, sorry ❤️.
        </p>
      </Container>
      <Container>
        <SubscribeForm
          title="Subscribe to 416serg"
          subTitle="Receive valuable content in your inbox every once in a while. No spam ever."
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query indexQuery {
    allContentfulPost(limit: 1000, sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            sizes(maxWidth: 800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`

export default Index
