import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Post = styled.li`
  border-radius: 10px;
  overflow: hidden;
  background: white;
  margin: 0 0 1em 0;
  width: 100%;
  transition: all 0.2s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: 0 0 49%;
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 32%;
  }
  &:hover {
    h2 {
      color: ${props => props.theme.colors.highlight};
    }
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    border-bottom: 5px solid ${props => props.theme.colors.highlight};
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
    }
  }
`

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  text-transform: capitalize;
  margin: 1rem 1rem 0.5rem 1rem;
  transition: all 0.2s;
`

const Date = styled.h3`
  margin: 0 1rem 1.5rem 1rem;
  font-weight: 500;
`

const Excerpt = styled.p`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
`

const Card = ({ slug, image, title, date, excerpt }) => (
  <Post>
    <Link to={`/${slug}`}>
      <Img sizes={image.sizes} />
      <Title>{title}</Title>
      <Date>{date}</Date>
      <Excerpt
        dangerouslySetInnerHTML={{
          __html: excerpt.childMarkdownRemark.excerpt,
        }}
      />
    </Link>
  </Post>
)

export default Card
