module.exports = {
  siteTitle: '416serg | Full Stack Javascript Developer',
  siteTitleAlt: "416ser's Blog", // This allows an alternative site title for SEO schema.
  publisher: '416serg', // Organization name used for SEO schema
  siteDescription:
    'Sergiy Dybskiy (416serg) is a full stack web developer specializing in React & NodeJS. He lives in Toronto and loves sharing what he has learnt through blog content & speaking. He has over 4 years of experience working with JavaScript applications.',
  siteUrl: 'https://416serg.me',
  author: '416serg', // Author for RSS author segment and SEO schema
  authorUrl: 'https://416serg.me/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@416serg', // Change for Twitter Cards
  shortTitle: '416serg Blog', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/social-new.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 960, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2018 416serg', // Copyright string for the RSS feed
}
