import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SubscribeForm from '../components/SubscribeForm'
import SubscribeBlurb from '../components/SubscribeBlurb'
import SEO from '../components/SEO'

const Subscribe = ({ data }) => {
  const postNode = {
    title: `Subscribe - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Subscribe - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="subscribe" customTitle />

      <Container>
        <PageTitle>Subscribe</PageTitle>
        <SubscribeBlurb />

        <SubscribeForm />
      </Container>
    </Layout>
  )
}

export default Subscribe
