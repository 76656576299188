import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  max-width: 650px;
  margin: 25px auto;
  h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 25px;
    em {
      font-weight: 600;
    }
  }
  ul {
    margin-bottom: 25px;
    &.dont {
      li:before {
        content: '❌';
      }
    }
    &.do {
      li:before {
        content: '✅';
        padding-right: 5px;
      }
    }

    li {
      &:before {
        padding-right: 5px;
      }
      line-height: 25px;
      margin-bottom: 5px;
    }
  }
  p {
    line-height: 1.4;
  }
`

const SubscribeBlurb = () => (
  <Content>
    <h3>
      What you <em>won't</em> get:
    </h3>
    <ul className="dont">
      <li>Spam</li>
      <li>Reposts</li>
      <li>Useless content</li>
    </ul>
    <h3>
      What you <em>will</em> get:
    </h3>
    <ul className="do">
      <li>An email every few weeks with a new helpful tip</li>
      <li>A piece of content that goes through a challenge I want to share</li>
      <li>Useful content</li>
    </ul>
  </Content>
)

export default SubscribeBlurb
