import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import netlify from '../../static/svgs/netlify.svg'
import gatsby from '../../static/svgs/gatsby.svg'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 1em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.dark};
    position: relative;
    text-decoration: none;
    &.highlight {
      &:before {
        content: '';
        background: ${props => transparentize(0.5, props.theme.colors.highlight)};
        width: 100%;
        position: absolute;
        bottom: 0px;
        height: 5px;
        transform: skew(-12deg);
      }
    }
    &:hover {
      color: ${props => props.theme.colors.highlight} !important;
    }
    &:visited {
      color: ${props => props.theme.colors.dark};
    }
  }
  .icon {
    display: flex;
    width: 20px;
    margin: 0 5px;
  }
`

const Footer = () => (
  <Wrapper>
    <List>
      <Item>
        <a href="https://www.contentful.com/" rel="nofollow" target="_blank">
          <img
            src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg"
            style={{ maxWidth: '100px', width: '100%' }}
            alt="Powered by Contentful"
          />
        </a>
      </Item>
      <Item>
        Built with ❤️ &amp;{' '}
        <a href="https://www.gatsbyjs.org/?ref=416serg.me" rel="noopener noreferrer" target="_blank">
          <img className="icon" src={gatsby} alt="Gatsby" />
        </a>{' '}
        and deployed on{' '}
        <a href="https://www.netlify.com/?ref=416serg.me" rel="noopener noreferrer" target="_blank">
          <img className="icon" src={netlify} alt="Netlify" />
        </a>
      </Item>
      <Item>
        <a href="https://twitter.com/416serg" rel="noopener noreferrer" className="highlight" target="_blank">
          416serg on Twitter
        </a>{' '}
        👋🏼&copy; {new Date().getFullYear()}
      </Item>
    </List>
  </Wrapper>
)

export default Footer
