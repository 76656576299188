import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import logo from '../images/logo-200.png'

const Header = styled.header`
  background: rgba(255,255,255,0.4);
  width: 100%;
  border-top: 5px solid ${props => props.theme.colors.highlight};
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 75px;
  }

  li {
    display: flex;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.dark};
    font-weight: 600;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      font-size: 1.2em;
    }
    transition: all 0.2s;
    display: block;
    position: relative;
    &:hover {
      color: ${props => props.theme.colors.base};
    }
    &.active {
      color: ${props => props.theme.colors.base};
      &:after {
        content: '';
        height: 2px;
        position: absolute;
        height: 3px;
        bottom: -28px;
        @media screen and (min-width: ${props => props.theme.responsive.small}) {
          bottom: -25px;
        }
        left: 0;
        width: 100%;
        background: ${props => props.theme.colors.highlight};
      }
    }
  }
`

const Menu = () => (
  <Header>
    <Nav>
      <ul>
        <li>
          <Link to="/">
            <h1>
              <img src={logo} alt="416serg" width={75} />
            </h1>
          </Link>
        </li>
        <li>
          <Link to="/about" activeClassName="active">
            About
          </Link>
        </li>
        <li>
          <Link to="/subscribe" activeClassName="active">
            Subscribe
          </Link>
        </li>
        <li>
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </li>
      </ul>
    </Nav>
  </Header>
)

export default Menu
